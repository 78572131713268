
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
// import AddinvoiceDrawer from '@/layout/header/partials/course/AddinvoiceDrawer.vue';
// import EditinvoiceInfoDrawer from '@/layout/header/partials/course/EditinvoiceInfoDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';
import moment from "moment";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

export default defineComponent({
  name: 'invoice-list',
  components: {
    Datatable,
    // AddinvoiceDrawer,
    // EditinvoiceInfoDrawer,
  },
  data() {
    return {
      load: true,
      first: true,
      componentKey: 0,
      moment: "" as any,
      lists: [],
      search: '',
      tableData: [],
      data: {},
      start_date: '' as any,
      end_date:'' as any, 
      po_number:'' as any,
      supplier_id: '' as any,
      supplierList: [] as any,
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      this.moment = moment;

      // await this.getinvoiceData();
      await this.getSupplierList();
      this.emitter.on('invoice-added', async () => {
        // await this.getinvoiceData();
        await this.getSupplierList();
      });
      $(this.$refs.purchaseList).DataTable({
        pageLength: 50,
        aLengthMenu: [
            [50, 100, 200, 500 -1],
            [50, 100, 200, 500, "All"]
        ],
      });
    } else {
      this.$router.push('/404');
    }
    this.load=false;
  },
  methods: {
    async getSupplierList() {
      await ApiService.get('finmanInvoice/supplier/list')
        .then((response) => {
          this.supplierList = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getinvoiceData() {
      var start_date= this.start_date;
      var end_date= this.end_date;
      var po_number= this.po_number;
      var supplier_id= this.supplier_id;
      this.load=true;
      await ApiService.get('finmanInvoice/invoice/list?type=2&startdate='+ `${start_date}` + '&enddate='+ `${end_date}` + '&supplier='+ `${supplier_id}` + '&po='+ `${po_number}`)
        .then((response) => {
          this.load= true;
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = false;
          this.first= false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    viewInvoice(invoice_id) {
      let invoiceData=[] as any;
      ApiService.get("finmanInvoice/invoice/getPurchaseDetails/"+ `${invoice_id}`)
        .then((response) => {
          invoiceData= response.data.data;
          console.log(response);
          this.emitter.emit('purchase-view-type', invoiceData);
        }).catch(({ response }) => {
              console.log(response);
        });
    },

    addPurchaseInvoice(){
      this.$router.push('add-purchase');
    },

    editInvoice(invoice_id) {
      this.emitter.emit('purchase-edit-type', invoice_id);
      this.$router.push(
        {
          path: 'add-purchase',
          // path: 'purchase-invoice',
          query: 
          {
            id: invoice_id,
          }
        });
    },

    Deleteinvoice(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/invoice/deletePurchase/' + `${id}`)
            .then((response) => {
              this.emitter.emit('invoice-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
